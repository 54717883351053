<template>
    <div>
        <v-dialog v-model="dataTransectionDialog" persistent max-width="950px">
            <v-card v-if="dataTransection">
                <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 mb-3"><v-app-bar height="50" flat
                        color="rgba(0, 0, 0, 0)">
                        <v-toolbar-title class="text-h5 pl-0 pt-0">
                            Detalhes da transação
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-app-bar></v-card-title>
                <v-card-text class="pt-3">
                    <v-row class="px-5">
                        <v-col class="pl-4" cols="3">
                            <h4><small color="text--black">Operadora</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.payment_gateway ?
                                dataTransection.payment_gateway.name : "" }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="5">
                            <h4><small color="text--black">Titular do cartão </small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.holder_name }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="4">
                            <h4><small color="text--black">Status </small></h4>
                            <h3 :class="`mb-2 font-weight-regular ${formatStatus(dataTransection.status)[3]}`">
                                <v-icon small style="cursor: pointer;" :color="formatStatus(dataTransection.status)[2]"
                                    dark>
                                    {{ formatStatus(dataTransection.status)[1] }}
                                </v-icon> {{ formatStatus(dataTransection.status)[0] }}
                            </h3>
                        </v-col>

                        <v-col class="pl-4" cols="4">
                            <h4><small color="text--black text--darken-4">Código de referência</small></h4>
                            <h3 class="mb-2 font-weight-regular font-weight-regular">{{ dataTransection.reference_code
                                }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="4">
                            <h4><small color="text--black">Data da transação</small></h4>
                            <h3 class="mb-2 font-weight-regular" v-if="dataTransection.transaction_date">{{
                                dataTransection.transaction_date.split(" ")[0] | formatDate }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="4">
                            <h4><small color="text--black">Valor</small></h4>
                            <h3 class="mb-2 success--text">R$ <span class="">{{ dataTransection.value | formatPrice
                                    }}</span>
                            </h3>
                        </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row class="px-5">
                        <v-col class="pl-4" cols="6">
                            <h4><small color="text--black">Código de pagamento</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.paymentId }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="2">
                            <h4><small color="text--black">Autorização</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.authorization_code }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="4">
                            <h4><small color="text--black">Código de referência</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.reference_code }}
                            </h3>
                        </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row class="px-5">
                        <v-col class="pl-4" cols="6">
                            <h4><small color="text--black">Pedido</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.merchant_order }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="6">
                            <h4><small color="text--black">Transaction code</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.transaction_code }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="6">
                            <h4><small color="text--black">Url de callback</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.callback_url }}
                            </h3>
                        </v-col>
                        <v-col class="pl-4" cols="6">
                            <h4><small color="text--black">Mensagem de retorno</small></h4>
                            <h3 class="mb-2 font-weight-regular">{{ dataTransection.returnMessage }}
                            </h3>
                        </v-col>
                    </v-row>
                    <v-divider class="my-5"></v-divider>
                    <v-row class="px-5">
                        <v-col class="pl-4" cols="12">
                            <h3 class="ml-1">Pagamento recorrente:
                                <span v-if="!dataTransection.recurrentPayment.recurrentPaymentId"
                                    class="pl-3 font-weight-regular red--text">Sem recorrência</span>
                                <span v-else-if="dataTransection.recurrentPayment.reasonCode == 2"
                                    class="pl-3 font-weight-regular red--text">Finalizado</span>
                                <span
                                    v-else-if="dataTransection.recurrentPayment.reasonCode != 0 && dataTransection.recurrentPayment.reasonCode != 1"
                                    class="pl-3 font-weight-regular red--text">{{
                                        dataTransection.recurrentPayment.reasonMessage }}</span>
                            </h3>
                        </v-col>
                    </v-row>
                    <v-row class="px-6"
                        v-if="dataTransection.recurrentPayment.reasonCode == 0 || dataTransection.recurrentPayment.reasonCode == 1">
                        <v-col class="pl-5 pt-0" cols="3">
                            <div class="mb-2 success--text">
                                <h3 class="font-weight-regular">Status: {{
                                    formatStatusRecurrence(dataTransection.recurrentPayment.reasonCode)[0] }} <v-icon
                                        small color="success">{{
                                            formatStatusRecurrence(dataTransection.recurrentPayment.reasonCode)[1]
                                        }}</v-icon>
                                </h3>
                            </div>
                        </v-col>
                        <v-col class="pl-5 pt-0" cols="4">
                            <div class="mb-2">
                                <h3 class="font-weight-regular">Vencimento dia: <span
                                        style="border: 1px solid #5a77b3; border-radius: 10px;"
                                        class="primary--text px-2 py-1 font-weight-bold">{{
                                            dataTransection.recurrentPayment.recurrence_day }}</span>
                                </h3>
                            </div>
                        </v-col>
                        <v-col class="pl-5 pt-0" cols="5">
                            <div class="mb-2">
                                <h3 class="font-weight-regular">Próximo vencimento: <span
                                        class="pl-1 font-weight-bold primary--text">{{
                                            dataTransection.recurrentPayment.nextRecurrency | formatDate }}</span>
                                </h3>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
                <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="toggleDataTransectionDialog"> Fechar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // key: value
        }
    },
    methods: {
        formatStatus(status) {
            switch (status) {
                case 0:
                    return ["Não finalizado", "highlight_off", "red", "red--text"];
                case 999:
                    return ["Falha na transação", "subtitles_off", "red darken-3", "red--text text--darken-3"];
                case 1:
                    return ["Autorizado", "verified_user", "success", "success--text"];
                case 2:
                    return ["Confirmado", "thumb_up_alt", "primary", "primary--text"];
                case 3:
                    return ["Não autorizado", "announcement", "orange darken-3", "orange--text text--darken-3"];
                case 4:
                    return ["Mudança status no pagamento recorrênte", "currency_exchange", "green darken-2", "green--text text--darken-2"];
                case 12:
                    return ["Aguardando Retorno", "hourglass_top", "warning", "warning--text"];
                default:
                    return ["Cancelado", "block", "grey darken-1", "grey--text text--darken-1"];
            }
        },
        formatStatusRecurrence(status) {
            switch (status) {
                case 0:
                    return ["Ativa", "mdi mdi-check-circle", "success", "success--text"];
                case 1:
                    return ["Reativada", "mdi mdi-check-circle", "success", "success--text"];
                case 2:
                    return ["Finalizada", "mdi mdi-close-octagon-outline", "blue-grey lighten-3", "blue-grey--text text--lighten-3"];
                case 3:
                    return ["Desativada", "mdi mdi-check-circle", "success", "success--text"];
                // case 4:
                //     return ["Ativa", "mdi mdi-check-circle", "success", "success--text"];
                // case 5:
                //     return ["Ativa", "mdi mdi-check-circle", "success", "success--text"];
                default:
                    return ["Desativada", "block", "grey darken-1", "grey--text text--darken-1"];
            }
        },
    },
    props: {
        dataTransection: Object,
        dataTransectionDialog: Boolean,
        toggleDataTransectionDialog: Function
    }
}
</script>

<style lang="scss" scoped></style>