<template>
    <v-form class="searchTransectionsFrm" ref="searchTransectionsFrm">

        <v-row class="justify-start px-5">
            <v-col cols="12" class="mt-5pt-0 pb-1 px-9 text-left">
                <span class="font-weight-medium">Filtros</span>
            </v-col>
            <v-col cols="3" class="pl-0 pr-3 pt-0">

                <v-menu ref="calendar" v-model="calendar" :close-on-content-click="false" transition="scale-transition"
                    max-width="290px" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                        <v-text-field class="caption rangeInputDate subtitle-2" prepend-icon="filter_alt" outlined dense
                            clearable v-model="dateRangeText" label="Definir período" v-bind="attrs"
                            v-on="on"></v-text-field>
                        <!-- hint="Selecione o primeiro e o último dia"
              persistent-hint -->
                    </template>
                    <v-date-picker class="rangeMode" range v-model="dates" no-title>
                        <div style="width: 100%" class="bordTop d-flex flex-row justify-start">
                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                OK
                            </v-btn>
                            <v-btn class="mt-2" text color="primary" @click="calendar = false">
                                Cancelar
                            </v-btn>
                        </div>
                    </v-date-picker>
                </v-menu>

                <!-- <v-menu v-model="start_dateMenu" :close-on-content-click="false" transition="scale-transition" offset-y
                    min-width="290px">
                    <template v-slot:activator="{ on }">
                        <v-text-field class="caption" prepend-icon="filter_alt" outlined dense clearable
                            v-model="start_dateFormated" label="Data da transação" readonly v-on="on"></v-text-field>
                    </template>
                    <v-date-picker no-title v-model="transaction_date" @input="start_dateMenu = false"></v-date-picker>
                </v-menu> -->
            </v-col>
            <!-- <v-col cols="2" class="pl-2 pr-3">
                    <v-menu v-model="end_dateMenu" :close-on-content-click="false" transition="scale-transition"
                        offset-y min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field outlined dense clearable v-model="end_dateFormated" label="Data final" readonly
                                v-on="on"></v-text-field>
                        </template>
                        <v-date-picker no-title v-model="end_date" @input="end_dateMenu = false"></v-date-picker>
                    </v-menu>
                </v-col> -->
            <v-col cols="12" md="2" class="pt-0">
                <v-text-field outlined dense v-model="holder_name" label="Titular do cartão" required></v-text-field>
            </v-col>
            <v-col cols="12" md="3" class="pt-0">
                <v-text-field outlined dense v-model="reference_code" label="Código de referência"
                    required></v-text-field>
            </v-col>
            <v-col class="d-flex pt-0" cols="12" sm="3">
                <v-select clearable outlined dense :items="transactionStatus" v-model="status"
                    label="Status"></v-select>
            </v-col>
            <v-col class="py-0" cols="1">
                <v-tooltip right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="sendForm()" v-bind="attrs" v-on="on" class="mt-1" fab dark x-small
                            color="blue-grey darken-1">
                            <v-icon dark x-small> search </v-icon>
                        </v-btn>
                    </template>
                    <span>Procurar</span>
                </v-tooltip>
            </v-col>
        </v-row>

    </v-form>
</template>

<script>
//   "transaction_date":"",
// 	"end_date":"",
// 	"status":"Confirmado",
// 	"merchant_order":"",         (Label para esse campo 'Pedido nro.')
// 	"app":"cielo"
import * as moment from "moment";
export default {
    computed: {
        dateRangeText: {
            // getter
            get: function () {
                return this.formatRangeDate(this.dates);
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.dates = [];
                }
            },
        },
        start_dateFormated: {
            get: function () {
                let date = moment(this.transaction_date).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.transaction_date = "";
                }
                let date = moment(this.transaction_date).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
        end_dateFormated: {
            get: function () {
                let date = moment(this.end_date).format("DD/MM/YYYY");
                return date == "Invalid date" ? null : date;
            },
            // setter
            set: function (newValue) {
                if (!newValue) {
                    this.end_date = "";
                }
                let date = moment(this.end_date).format("DD/MM/YYYY");
                if (date == "Invalid date") {
                    return null;
                }
            },
        },
    },
    watch: {
        creditCardTransectionsDialog(v) {
            if (!v) {
                this.reseteForm()
            }
        }
    },
    methods: {
        formatRangeDate(listDates) {
            let returnDate = "";
            for (let i = 0; i < listDates.length; i++) {
                let [year, month, day] = listDates[i].split("-");
                returnDate += `${day}/${month}/${year}${i == 0 ? " - " : ""}`;
            }

            return returnDate;
        },
        reseteForm() {
            this.transaction_date = ""
            this.end_date = ""
            this.merchant_order = ""
            this.status = ""
            this.transaction_code = ""
        },
        sendForm() {
            let data = {
                start_date: this.dates[0],
                end_date: this.dates[1],
                transaction_date: this.transaction_date,
                holder_name: this.holder_name,
                status: this.status,
                reference_code: this.reference_code,
            }
            this.getTransections(data, 1)
        }
    },
    data() {
        return {
            dates: [],
            calendar: false,
            transactionStatus: ["Não finalizado", "Autorizado", "Confirmado", "Não autorizado", "Cancelado", "Falha", "Aguardando retorno"],
            transaction_date: "",
            start_dateMenu: false,
            end_date: "",
            end_dateMenu: false,
            holder_name: "",
            reference_code: "",
            status: "",
        }
    },

    props: {
        getTransections: Function,
        creditCardTransectionsDialog: Boolean
    },
}
</script>

<style>
.searchTransectionsFrm label {
    font-size: 14px !important;
}
</style>