<template>
    <div>
        <v-row class="mt-1 mb-2 d-flex align-start justify-space-between">
            <v-col cols="12">
                <h4 class="titlePages text-left font-weight-medium mb-0">Pagamentos
                </h4>
                <p style="max-width: 60%;" class="text-body-2 font-weight-light text-left mt-2">
                    Acesse seus produtos de pagamento para controlar seu faturameto, gerenciar e gerar boletos,
                    configurar operadoras de cartões de crédito e visualizar suas transações.
                </p>
            </v-col>
            <v-col cols="12" class="d-flex justify-start">
                <v-card class="mt-5" max-width="320">
                    <v-img min-width="320" max-height="160" min-height="160" class="" :src="billetImg"></v-img>
                    <v-card-text class="pt-5" style="border-top: 2px solid #C62828;">
                        <h6 class="title red--text text--darken-3 text-center mb-3">Boletos bancários</h6>
                        <div class="px-3 text-center">
                            Configure seus bancos, crie seus perfis de cobranças e gerencie seus boletos.
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-center py-3">
                        <v-btn @click="toogleBilletDialog" class="" text color="red darken-3">Acessar
                            boletos</v-btn>
                    </v-card-actions>
                </v-card>
                <v-card class="mt-5 ml-15" max-width="320">
                    <v-img min-width="320" max-height="160" class="" :src="creditCardImg"></v-img>
                    <v-card-text class="pt-5" style="border-top: 2px solid #4caf50;">
                        <h6 class="title success--text text-center mb-3">Cartões de crédito</h6>
                        <div class="px-3 text-center">
                            Configure, gerencie suas operadoras, visualize trasações e recebimantos.
                        </div>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="justify-center py-3">
                        <v-btn @click="toogleCreditCardDialog" class="" text color="success">Acessar Cartões</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <BilletsDialog :toogleBilletDialog="toogleBilletDialog" :billetDialog="billetDialog" />
        <CreditCardDialog :toogleCreditCardDialog="toogleCreditCardDialog" :creditCardDialog="creditCardDialog" />
    </div>
</template>

<script>
import BilletsDialog from './BilletsDialog.vue'
import CreditCardDialog from './creditCard/CreditCardDialog.vue'
export default {
    beforeMount() {
        // console.log('aqui')
        document.getElementsByClassName("pgtos")[0].click();
        setTimeout(() => {
            document.getElementsByClassName("adm-boletos")[0].click();
        }, 10);
    },
    methods: {
        toogleBilletDialog() {
            this.billetDialog = !this.billetDialog
        },
        toogleCreditCardDialog() {
            this.creditCardDialog = !this.creditCardDialog
        }
    },
    data() {
        return {
            billetImg: require("@/assets/newArea/bank-slip.jpg"),
            creditCardImg: require("@/assets/newArea/credit-card.jpg"),
            billetDialog: false,
            creditCardDialog: false
        }
    },
    components: {
        BilletsDialog,
        CreditCardDialog
    },
}
</script>

<style lang="scss" scoped></style>