<template>
    <div>
        <TransectionsFIlters :getTransections="getTransections" />
        <!-- :creditCardTransectionsDialog="creditCardTransectionsDialog" -->

        <v-row class="px-8 pb-5 justify-end">
            <v-btn @click="toogleCreditCardLogDilog" class="mx-0 py-3" small color="deep-orange darken-3" dark>
                <v-icon small left> integration_instructions </v-icon>Logs
            </v-btn>
        </v-row>
        <v-data-table class="newPagination" :page="1" :itemsPerPage="perPage" :headers="headers" :items="transections"
            item-key="id">
            <template v-slot:item.status="{ item }">
                <v-tooltip left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-menu bottom v-if="formatStatus(item.status)[1] == 'subtitles_off'" open-on-hover
                            :closeOnContentClick="false">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn elevation="1" x-small fab>
                                    <v-icon style="cursor: pointer;" :color="formatStatus(item.status)[2]" dark
                                        v-bind="attrs" v-on="on">
                                        {{ formatStatus(item.status)[1] }}
                                    </v-icon>
                                </v-btn>
                            </template>

                            <v-card max-width="340">
                                <v-list>
                                    <v-list-item>
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                Código do erro: <b class="deep-orange--text text--darken-3">{{
                                                    item.fail.fail_message.Error[0].code }}</b>
                                            </v-list-item-title>
                                            <div class="mt-3"><small class="font-weight-bold">Mensagem:</small></div>
                                            <p class="mt-1"><span class="">{{
                                                item.fail.fail_message.Error[0].returnMessage
                                                    }}</span></p>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-list>
                            </v-card>

                        </v-menu>
                        <v-icon v-if="formatStatus(item.status)[1] != 'subtitles_off'" style="cursor: pointer;"
                            :color="formatStatus(item.status)[2]" dark v-bind="attrs" v-on="on">
                            {{ formatStatus(item.status)[1] }}
                        </v-icon>
                    </template>
                    <span>{{ formatStatus(item.status)[0] }}</span>
                </v-tooltip>
            </template>
            <template v-slot:item.recurrentPayment="{ item }">
                <div v-if="item.recurrentPayment.reasonCode == 0 || item.recurrentPayment.reasonCode == 1">
                    <!-- <div class="font-weight-medium text-center">Sim</div> -->
                    <span><a @click="toggleDataRecurrenceDialog(item.recurrentPayment)" href="#">Detalhes+</a></span>
                </div>
                <div v-else-if="item.recurrentPayment.reasonCode == 2 || item.recurrentPayment.reasonCode == 3"
                    class="font-weight-medium text-left grey--text text--darken-1">{{
                        item.recurrentPayment.reasonCode == 2 ? 'Finailzada' : 'Desativada'
                    }}</div>
                <div v-else class="font-weight-medium text-left red--text text--darken-4">Não</div>

            </template>
            <template v-slot:item.authorization_code="{ item }">
                <div>
                    <small class="mr-2">Aut:</small>
                    <span class="font-weight-medium">{{ item.authorization_code }}
                    </span>
                </div>
                <div>
                    <small class="mr-2">Referência:</small>
                    <span class="font-weight-medium">{{ item.reference_code }}
                    </span>
                </div>
            </template>
            <template v-slot:item.transaction_date="{ item }">

                <div class="">
                    <small class="mr-2">Data:</small>
                    <span class="font-weight-medium">{{ item.transaction_date.split(' ')[0] | formatDate }}
                    </span>
                </div>
                <div class="">
                    <small class="mr-2">Cod:</small>
                    <span class="font-weight-medium">{{ item.transaction_code ? item.transaction_code : '-' }}
                    </span>
                </div>
            </template>

            <template v-slot:item.value="{ item }">
                <div style="min-width: 100px;">
                    R$ {{ item.value | formatPrice }}
                </div>
            </template>


            <!-- <template v-slot:item.data-table-expand="{ item, isExpanded }">
                <v-btn :disabled="Array.isArray(item.recurrentPayment)" color="primary"
                    @click="handleExpansion(item, isExpanded)" outlined rounded x-small>
                    Recorrência
                </v-btn>
            </template> -->
            <template v-slot:item.data-table-expand="{ item, isExpanded }">
                <!-- <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="Array.isArray(item.recurrentPayment)" v-bind="attrs" v-on="on"
                            :class="[Array.isArray(item.recurrentPayment) ? 'disableBt' : '', 'recurrence btAverageSize px-1 mr-2']"
                            @click="handleExpansion(item, isExpanded)" color="primary" dark>
                            <v-icon center size="16"> today </v-icon></v-btn>
                    </template>
<span class="miniTtip">Recorrência</span>
</v-tooltip> -->

                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn v-bind="attrs" v-on="on" :class="['callHistory btAverageSize px-1 mr-2 green darken-2']"
                            @click="toggleDataTransectionDialog(item)">
                            <v-icon color="white" size="18">receipt</v-icon>
                        </v-btn>
                    </template>
                    <span class="miniTtip">Detalhes da transação</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn :disabled="item.callback_history.length ? false : true" v-bind="attrs" v-on="on"
                            :class="[item.callback_history.length ? '' : 'disableBt', 'callHistory btAverageSize px-1']"
                            @click="showCallbackHistory(item.callback_history, item.transaction_code)"
                            color="indigo darken-1" dark><v-icon center size="18"> history
                            </v-icon></v-btn>
                    </template>
                    <span class="miniTtip">Histórico de callbacks</span>
                </v-tooltip>
            </template>




            <!-- <template v-slot:expanded-item="{ headers, item }">
                <td style="border-right: 3px solid #ccc !important;" class="pt-1 pb-2">
                    <h5 class="mt-1">Recorrências</h5>
                </td>
                <td class="text-left">
                    <div><span class="mr-2">Status:</span><b>{{ item.recurrentPayment.reasonMessage }}</b></div>
                </td>
                <td class="text-left">
                    <div class="text--center"><span class="mr-2">Vencimento:</span><b>{{
                        item.recurrentPayment.recurrence_day }}</b>
                    </div>
                </td>
                <td class="text-left" width="400">
                    <div><span class="mr-2">Próximo vencimento:</span><b>{{ item.recurrentPayment.nextRecurrency |
                        formatDate }}</b>
                    </div>
                </td>
                <td></td>
                <td></td>
            </template> -->
            <template v-slot:footer.page-text>
                <v-row class="d-flex justify-end">
                    <v-col class="pb-0" cols="5">
                        <v-container class="d-flex flex-row justify-end max-width pr-0">
                            <v-pagination v-model="actualPage" :length="totalPages" :total-visible="8"></v-pagination>
                        </v-container>
                    </v-col>
                    <v-col style="max-width: 130px;" class="pt-9 px-0 pb-0" cols="2"><span class="caption">Itens por
                            página</span></v-col>
                    <v-col style="max-width: 90px;" class="pb-0" cols="2">
                        <v-select v-model="perPage" :items="[5, 10, 50, 100]"></v-select>
                    </v-col>
                </v-row>
            </template>
        </v-data-table>
        <CallbackHistoryList :transactionCode="transactionCode" :toogleCallbackHistoryList="toogleCallbackHistoryList"
            :callbacksList="callbacksList" :callbackHistoryDialog="callbackHistoryDialog" :creditCard="true" />
        <CreditCardLogs :creditCardLogDilog="creditCardLogDilog" :toogleCreditCardLogDilog="toogleCreditCardLogDilog" />
        <RecurrenceDetails :dataRecurrence="dataRecurrence" :dataRecurrenceDialog="dataRecurrenceDialog"
            :toggleDataRecurrenceDialog="toggleDataRecurrenceDialog" />
        <TransectionDetails :toggleDataTransectionDialog="toggleDataTransectionDialog"
            :dataTransectionDialog="dataTransectionDialog" :dataTransection="dataTransection" />
    </div>
</template>

<script>
import TransectionsFIlters from './TransectionsFIlters.vue'
import RecurrenceDetails from './admComponents/RecurrenceDetails.vue'
import TransectionDetails from './admComponents/TransectionDetails.vue'
import CallbackHistoryList from "../../../../../components/clients/billets/newManagerBillets/CallbackHistoryList.vue";
import CreditCardLogs from "../../../../../components/clients/billets/CreditCards/logs/CreditCardLogs.vue"
export default {
    watch: {
        actualPage(v, oldV) {
            //console.log("qsqsqs", oldV);
            if (!oldV || !v) {
                return;
            }
            this.getTransections(this.$store.state.lastCreditCardsParans, v, v)
        },
        perPage(v) {
            // console.log('perpag', v)
            // this.$store.state.lastBilletsParans, 
            this.getTransections(this.$store.state.lastCreditCardsParans, 1, v)
        },
        operator(v, oldV) {
            if (v != oldV) {
                this.getTransections(this.$store.state.lastCreditCardsParans, 1, v)
            }
        }
    },
    methods: {
        toggleDataTransectionDialog(data) {
            // console.log('aassdas', data)
            this.dataTransectionDialog = !this.dataTransectionDialog
            if (this.dataTransectionDialog) {
                this.dataRecurrence = {}
                this.dataTransection = { ...data }
            }
        },
        toogleCreditCardLogDilog() {
            this.creditCardLogDilog = !this.creditCardLogDilog
        },
        toogleCallbackHistoryList() {
            // this.callbacksList = []
            this.callbackHistoryDialog = !this.callbackHistoryDialog
        },
        showCallbackHistory(data, code) {

            this.callbacksList = []
            setTimeout(() => {
                this.callbacksList = [...data]
            }, 10);
            this.transactionCode = code
            this.toogleCallbackHistoryList()
        },
        formatStatus(status) {
            switch (status) {
                case 0:
                    return ["Não finalizado", "highlight_off", "red"];
                case 999:
                    return ["Falha na transação", "subtitles_off", "red darken-3"];
                case 1:
                    return ["Autorizado", "verified_user", "success"];
                case 2:
                    return ["Confirmado", "thumb_up_alt", "primary"];
                case 3:
                    return ["Não autorizado", "announcement", "orange darken-3"];
                case 4:
                    return ["Mudança status no pagamento recorrênte", "currency_exchange", "green darken-2"];
                case 12:
                    return ["Aguardando Retorno", "hourglass_top", "warning"];
                default:
                    return ["Cancelado", "block", "grey darken-1"];
            }
        },
        resetPagination() {
            this.transections = []
            this.actualPage = 1
            this.totalPages = 0
        },
        getTransections(data, page) {
            let complementPag = page ? `/?page=${page}` : "";
            this.actualPage = page;
            let payload = { web: true, app: "cielo", ...data }
            var obj = {
                url: "/api/v1/creditcard/transaction/getAll" + complementPag,
                query: payload,
                method: "post",
            };
            obj.query.per_page = this.perPage;
            this.$store.commit("updateLastCreditCardsParans", obj.query);
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                console.log('datdatda')
                if (Array.isArray(response.data)) {
                    this.resetPagination()
                    return
                }
                this.transections = [...response.data.data];
                this.totalPages = response.data.last_page;
            });
        },
        handleExpansion(item, state) {
            // console.log(item, state);
            this.expanded = [];
            const itemIndex = this.expanded.indexOf(item);
            state ? this.expanded.splice(itemIndex, 1) : this.expanded.push(item);
        },
        toggleDataRecurrenceDialog(data) {
            this.dataRecurrenceDialog = !this.dataRecurrenceDialog
            if (this.dataRecurrenceDialog) {
                this.dataRecurrence = {}
                this.dataRecurrence = { ...data }
            }
        }
    },
    data() {
        return {
            setedPage: false,
            totalPages: 0,
            perPage: 5,
            actualPage: 0,
            dataTransection: null,
            dataTransectionDialog: false,
            dataRecurrence: {},
            dataRecurrenceDialog: false,
            creditCardLogDilog: false,
            callbackHistoryDialog: false,
            callbacksList: [],
            transactionCode: "",
            expanded: [],
            transections: [],
            expanded: [],
            transections: [],
            headers: [
                {
                    text: "Status",
                    value: "status",
                    align: "center",
                    sortable: false,
                },
                {
                    text: "Titular do cartão",
                    value: "holder_name",
                    align: "left",
                    sortable: true,
                },
                {
                    text: "Recorrência",
                    value: "recurrentPayment",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Autorização/Cod. Referência",
                    value: "authorization_code",
                    align: "left",
                    sortable: false,
                },
                // {
                //     text: "Número do pedido",
                //     value: "merchant_order",
                //     align: "center",
                //     sortable: false,
                // },
                {
                    text: "Data/Transaction code",
                    value: "transaction_date",
                    align: "left",
                    sortable: false,
                },
                // {
                //     text: "Mensagem de retorno",
                //     value: "returnMessage",
                //     align: "left",
                //     width: 250,
                //     sortable: false,
                // },
                {
                    text: "Valor",
                    value: "value",
                    align: "left",
                    sortable: false,
                },
                { text: 'Ações', width: 130, value: 'data-table-expand', align: "center", },
            ]
        }
    },
    props: {
        operator: String
    },
    components: {
        TransectionsFIlters,
        CallbackHistoryList,
        CreditCardLogs,
        RecurrenceDetails,
        TransectionDetails
    },
}
</script>

<style>
.v-data-table__expanded__content {
    box-shadow: inset 1px -3px 4px -2px rgba(102, 102, 102, 1) !important;
    background: #f2fbff !important;
}

.v-btn.v-btn--disabled.btAverageSize.disableBt.recurrence {
    background-color: #1976D2 !important;
    border-color: #1976D2 !important;
    opacity: 0.4 !important;
}

.v-btn.v-btn--disabled.btAverageSize.disableBt.callHistory {
    background-color: #3949AB !important;
    border-color: #3949AB !important;
    opacity: 0.7 !important;
}
</style>