<template>
    <v-dialog persistent v-model="infoDocDialog" scrollable max-width="1024px">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2"><v-app-bar height="50" flat
                    color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        Leia atentamente a documentação
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleinfoDocDialog" class="mt-6" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar></v-card-title>
            <v-card-text class="pt-8 infoDoc">
                <div v-html="infoDoc">
                </div>
                <!-- <h1>• Caso você ainda não tenha uma conta no Banco do Brasil</h1>
                <p class="pl-1">
                    Será necessário entrar em contato com um gerente de
                    contas, contratar o serviço e assim obter o Número de Agência, Conta Corrente, Número de carteira e
                    Número de Variação de
                    Carteira, os quais posteriormente vão ser cadastrados no Portal Community Ware.
                </p>

                <h3>- Primeiro passo</h3>
                <p class="pl-1">O primeiro passo é a contratação do serviço de Cobrança Bancária.<br />Caso você já
                    tenha contratado o serviço e possua o Número de Convênio, pode ir
                    diretamente para o seu registro no Portal Desenvolvedor BB - Banco do Brasil, detalhado no
                    <b>Segundo
                        passo</b>.
                </p>

                <div class="mb-4">
                    A contratação do convênio é o momento em que a sua empresa indicará as especificidades requeridas do
                    serviço. O convênio de Cobrança Bancária pode ser fácil e rapidamente contratado através do BB
                    Digital PJ, o
                    internet banking do BB.<br />
                    No BB Digital PJ acesse Contratação de Serviço > Cobrança e Pagamentos > Contratar serviço.
                    Para acessar diretamente o BB Digital PJ e contratar ao convênio, <a target="_blank"
                        class="white--text"
                        href="https://autoatendimento.bb.com.br/apf-apj-acesso/?tipoCliente=empresa&segmento=voce&pk_vid=1f83ffa38473ca3f1601939853096f23&v=2.21.4&t=1"
                        blan>clique aqui.</a><br />
                    A contratação também pode ser feita diretamente com seu gerente no banco.
                </div>
                <div class="boxdetail primaryNew">
                    O número de convênio recebido após contratação do serviço, deve ser cadastrado juntamente com o
                    número da
                    agência, conta corrente, número de carteira e o número de variação de carteira nas configurações do
                    banco no
                    Portal Community Ware, acessando:<br />
                    <span class="mt-2"><b>ÁREA ADM. PAGAMENTOS > Boletos > Configuração > Bancos > Cadastrar novo banco
                            > Dados do
                            banco</b></span>.
                </div>
                <div class="greyBox">
                    <b>Observações:</b><br />
                    O número de Agência e o número da Conta Corrente, não podem possuir zeros à esquerda, traços e nem
                    dígito verificador.<br />
                    - Ex. Se seu número de Agência é 0452-4, você só deve preencher: 452.<br />
                    Consulte com seu gerente de contas quais as tarifas cobradas pelo banco na emissão de
                    boletos.<br />
                    As tarifas geralmente cobradas pelo banco são:<br />
                    * por boleto registrado (emissão)<br />
                    * por boleto liquidado (pago)<br />
                    * por boleto baixado/cancelado.<br />
                    As tarifas devem ser cadastradas no Portal Community Ware, acessando:<br />
                    <span class="mt-2"><b>ÁREA ADM. PAGAMENTOS > Boletos > Configuração > Bancos > Cadastrar novo banco
                            > Tarifas do Banco</b></span>.
                </div>
                <h3>- Segundo passo</h3>
                <p>O segundo passo é acessar e fazer o seu cadastro no Portal Desenvolvedor BB - Banco do Brasil<br />
                    É muito simples. No Portal Desenvolvedor BB (se preferir, acesse <a target="_blank"
                        href="https://www.bb.com.br/site/developers/">https://www.bb.com.br/site/developers/</a>)
                    Clique no botão Cadastre-se no canto superior da página e preencha os seguintes dados: CPF, nome da
                    mãe,
                    data de nascimento, endereço de e-mail e número de celular válido.</p>
                <div class="mb-4">
                    Sempre desconsidere acentuações e caracteres especiais.<br />
                    Será remetido um código de confirmação para o número de telefone cadastrado no Banco. Informe o
                    código recebido e cadastre uma senha conforme instruções indicadas em tela.<br />
                    Caso você tenha recebido um e-mail com convite do BB para primeiro ingresso no Portal Desenvolvedor
                    BB, após o primeiro acesso, informe a chave/código indicada na mensagem.<br />
                    Leia e assine eletronicamente o Termo para Uso da Plataforma. Você aproveitará um ambiente super
                    seguro para desenvolver a sua aplicação.
                </div>
                <div class="boxdetail primaryNew">
                    <b>Crie um ambiente de testes</b><br />
                    A criação de um ambiente próprio de testes não é obrigatória, mas recomendamos o procedimento, uma
                    vez que o desenvolvedor necessita compreender
                    se os comportamentos dos sistemas integrados pela API estão adequados à necessidade de utilização
                    dos serviços financeiros e à experiência desejada por sua empresa ou clientes.<br />
                    A disponibilização da sua aplicação em produção dependerá do sucesso dos testes executados em algum
                    software de desenvolvimento e teste de APIs disponível no mercado.
                </div>
                <div class="greyBox">
                    <b>Crie sua aplicação</b><br />
                    Uma vez cadastrado no Portal Desenvolvedor BB será exigida a criação de uma aplicação.<br />
                    Acesse o contêiner (“+ Nova Aplicação”) e atribua as seguintes informações:<br />
                    * nome da aplicação<br />
                    * descrição<br />
                    * URL de imagem para ícone da área de desenvolvimento (não obrigatório)<br />
                    * URI de call-back.<br /><br />
                    Selecione pelo menos uma API do grupo com contratação online pelo Portal Desenvolvedor BB.<br />
                    As APIs desse grupo você contrata direto pelo Portal Desenvolvedor BB. Basta selecionar uma ou mais
                    APIs, verificar se possui os requisitos necessários para a contratação. Na hora de ir para produção
                    éstas APIs vão estar disponibilizadas.<br />
                    Criada a aplicação, clique sobre o contêiner do app para acessar a área do desenvolvedor. As
                    credenciais necessárias para realização dos testes são geradas automaticamente
                    neste momento e poderão ser identificadas na opção Credenciais do menu lateral esquerdo.
                </div>
                <div class="greyBox">
                    <b>Detalhamos algumas informações que o contêiner possui para que você identifica-lo.</b><br />
                    * Identificador Ex.: ID:66546<br />
                    * Nome que você deu à aplicação Ex Cobrança-BB<br />
                    * Se está em Teste ou Produção<br />
                    * Data da criação<br />
                    * Descrição que você escreveu no momento da criação<br />
                    * APIs vinvuladas<br />
                    Dentro da área do desenvolvedor é possível acompanhar as estatísticas decorrentes dos testes
                    executados,
                    incluir outros desenvolvedores para trabalhar no projeto, gerenciar o
                    time de desenvolvimento, identificar e manusear o client secret e client id gerados pelo sistema
                    para
                    permitir os testes, acessar o Swagger e outros documentos técnicos
                    detalhados da API em desenvolvimento, excluir a aplicação ou colocá-la em produção.
                </div>
                <h3>- Credenciais</h3>
                <p>
                    Ao acessar sua aplicação, será disponibilizado um menu lateral à esquerda, escolha a opção
                    “Credenciais”.<br />
                    Verifique se a opção Teste encontra-se selecionada em azul.
                </p>
                <div class="greyBox">
                    O primeiro item disponibilizado é a credencial: <b>"developer_application_key"</b>, que serve para
                    acionar as APIS do Banco do Brasil.<br />
                    * Essa credencial será informada no campo <b>"App_key"</b> da aba credenciais no cadastro de bancos
                    do portal Community Ware.<br /><br />
                    Logo abaixo, seguem as credenciais <b>"OAuth"</b>, as quais deverão ser utilizadas para gerar á
                    autenticação no Banco do Brasil.<br />
                    O <b>client_id</b> é o identificador público e único no <b>OAuth</b> do Banco do Brasil.<br />
                    * Esse campo será informada no campo <b>"Client id"</b> da aba credenciais no cadastro de bancos
                    do portal Community Ware.<br /><br />
                    O <b>client_secret</b> é utilizado apenas para sua aplicação e o servidor de autorização. Por isso,
                    tome muito cuidado com seu armazenamento, Em caso de suspeita de fraude, acesse suas credenciais
                    dentro da sua Aplicação e faça a troca.<br />
                    * Esse campo será informada no campo <b>"Clien secret"</b> da aba credenciais no cadastro de bancos
                    do portal Community Ware.<br /><br />
                    O <b>Basic</b> do Authorization pode ser copiado clicando no botão “Copiar Basic”.<br />
                    * Esse campo será informada no campo <b>"Auth code"</b> da aba credenciais no cadastro de bancos
                    do portal Community Ware.<br /><br />
                    * No campo <b>Url de callback</b> (não obrigatório) na aba credenciais no cadastro de bancos
                    do portal Community Ware você pode cadastrar uma url para o sistema retornar os status dos boletos.
                    Ela será utilizada como url padrão para todos os retornos.
                </div>

                <h3>- Terceiro passo</h3>
                <p>
                    O terceiro passo é enviar a sua aplicação para <b>produção</b>.<br />
                    Para utilizar as APIs do BB no seu negócio, é necessário o envio da sua aplicação para produção.
                </p>
                <div class="boxdetail primaryNew">
                    Selecione qual aplicação você deseja enviar para produção, é importante ressaltar que a aplicação
                    deverá estar com status <b>"Em Teste"</b>.<br />
                    No menu lateral, selecione a opção <b>"Produção"</b> e siga os passos:<br /><br />
                    * Informe o CNPJ da empresa responsável.<br />
                    Revise as informações da sua aplicação.Caso a sua aplicação tenha a API de Cobrança vinculada, a
                    contratação poderá ser feita de 2 formas: Com ou sem o contato do BB.<br /><br />
                    * A contratação é feita sem a interferência do BB, caso a empresa já possua contratado um convênio
                    de cobrança e este esteja ativo.<br /><br />
                    * Na inexistência de contrato prévio, sua aplicação ficará com status <b>"Aguardando Aprovação"</b>.
                    Nesse
                    caso, aguarde pelo contato do Banco do Brasil para concluir a contração.
                    Assim que a aplicação for enviada para produção, o status será alterado para <b>"Aprovado"</b>.
                </div>
                <div class="greyBox">
                    <b>Gerando credenciais para produção</b><br />
                    A decisão de subir a aplicação para o ambiente de produção é de responsabilidade exclusiva da
                    empresa contratante do serviço financeiro integrado pela API, assim recomendamos
                    que os desenvolvedores e os responsáveis administrativos certifiquem-se de que todos os cenários e
                    adequações inerentes ao modelo de negócio ao qual a API será utilizada foram
                    testados exaustivamente.<br />
                    Os efeitos da integração dos sistemas por API somente ocorrerá a partir da disponibilização da
                    aplicação em produção.
                </div>
                <div class="greyBox">
                    Selecione qual aplicação você deseja enviar para produção. É importante lembrar que a aplicação deve
                    estar com seu status "Em Teste".<br />
                    Na área do desenvolvedor, acesse a:<br />
                    * opção Produção no menu lateral esquerdo, informe o CNPJ da empresa responsável, revise os dados da
                    aplicação e comande a disponibilização.<br /><br />
                    * Nesse momento sua aplicação permanecerá com status <b>Aguardando aprovação</b>.<br />
                    Após o envio da aplicação para produção e retirada de pendência pelos outorgados, o status será
                    alterado para <b>Aprovado</b>.<br /><br />
                    * Verifique se o item Produção ficou selecionado em azul.<br /><br />
                    * As credenciais deveram ser cadastradas no Portal Community Ware, acessando:<br />
                    <b>ÁREA ADM. PAGAMENTOS > Boletos > Configuração > Bancos > Cadastrar novo banco > Credenciais.</b>
                    * Informar <b>"developer_application_key"</b> no campo <b>App_key</b> da aba Credenciais.<br />
                    * O identificador público <b>client_id</b>, deve ser informado no campo <b>Client id</b> na aba
                    Credenciais.<br />
                    * O identificador privado <b>client_secret</b>, deve ser informado no campo <b>Clien secret</b> na
                    aba Credenciais.<br />
                    * O <b>Basic do Authorization</b>, deve ser informado no campo <b>Auth code</b> na aba Credenciais.
                </div> -->
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleinfoDocDialog">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data() {
        return {
        }
    },
    props: {
        infoDoc: String,
        infoDocDialog: Boolean,
        toogleinfoDocDialog: Function
    },
}
</script>

<style>
.infoDoc h1 {
    font-size: 20px !important;
    margin-bottom: 10px;
}

.infoDoc .boxdetail {
    border-radius: 3px;
    color: white;
    padding: 10px 15px;
    margin: 5px 0 15px 0;
}

.infoDoc .greyBox {
    color: #333;
    background-color: #eee;
    border-radius: 3px;
    padding: 10px 15px;
    margin: 5px 0 15px 0;
}
</style>