var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"width":"50%"}},[_c('v-card-title',[_vm._v(" Bancos ")]),_c('v-card-subtitle',{staticClass:"text-left"},[_vm._v(" Lista de bancos cadastrados ")]),_c('v-divider',{staticClass:"deep-orange darken-2",staticStyle:{"border":"solid 2px"}}),_c('div',{staticStyle:{"position":"relative"}},[_c('v-fab-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"deep-orange darken-2","fab":"","dark":"","small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleRegisterbankDilog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Cadastrar novo banco")])])],1)],1),_c('v-card-text',{},[_c('v-data-table',{staticClass:"elevation-0 mt-2",attrs:{"headers":_vm.headers,"items":_vm.$store.state.configurdBanksList,"items-per-page":5,"no-data-text":"Nenhum banco configurado"},scopedSlots:_vm._u([{key:"item.bank.bank_name",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.bank.bank_name))]),_c('div',[_c('small',[_vm._v("Convênio: "),_c('b',[_vm._v(_vm._s(item.title_config ? item.title_config.numeroConvenio : "-"))])])])]}},{key:"item.title_config.numeroCarteira",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title_config && item.title_config.numeroCarteira ? item.title_config.numeroCarteira : "-")+" ")]}},{key:"item.title_config.orgaoNegativador",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.title_config ? item.title_config.orgaoNegativador : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primaryNew","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","rounded":""}},[_c('v-subheader',{staticClass:"text-subtitle-2"},[_c('b',{staticClass:"primaryNew--text"},[_vm._v(" "+_vm._s(item.bank.bank_name)+" ")])]),_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item',{on:{"click":function($event){return _vm.editConfigBank(item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("drive_file_rename_outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar banco")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.confirmDelConfig(item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("delete_forever")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Excluir banco")])],1)],1)],1)],1)]}}])})],1),_c('RegisterBank',{attrs:{"adm":false,"verifySteps":_vm.verifySteps,"getConfiguredBanks":_vm.getConfiguredBanks,"editConfigData":_vm.editConfigData,"registerbankDilog":_vm.registerbankDilog,"toogleRegisterbankDilog":_vm.toogleRegisterbankDilog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }