<template>
    <v-card class="mt-5 ml-10" width="450">
        <v-card-title>
            Boletos
        </v-card-title>
        <v-card-subtitle>
            Gerencie seus boletos
        </v-card-subtitle>
        <v-divider style="border: solid 2px;" class="success"></v-divider>
        <div style="position: relative;"></div>
        <v-card-text class="py-6">
            <v-row>
                <v-col cols="12" md="10" class="mt-2 text-left">
                    <v-avatar class="mr-3" color="green lighten-4" rounded size="36"><v-icon color="success"
                            size="24">monetization_on</v-icon></v-avatar>
                    <span class="text-subtitle-2 success--text">Boletos liquidados</span>
                </v-col>
                <v-col cols="12" md="2" class="mt-2 text-right">
                    <v-chip class="my-2 px-2" small>
                        <b>{{ statusBillets[0] }}</b>
                    </v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" class="mt-2 text-left">
                    <v-avatar class="mr-3" color="primary lighten-4" rounded size="36"><v-icon color="primary"
                            size="24">verified</v-icon></v-avatar>
                    <span class="text-subtitle-2 primary--text">Boletos registrados</span>
                </v-col>
                <v-col cols="12" md="2" class="mt-2 text-right">
                    <v-chip class="my-2 px-2" small>
                        <b>{{ statusBillets[1] }}</b>
                    </v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" class="mt-2 text-left">
                    <v-avatar class="mr-3" color="orange lighten-4" rounded size="36"><v-icon color="warning"
                            size="24">query_builder</v-icon></v-avatar>
                    <span class="text-subtitle-2 warning--text">Aguardando registro</span>
                </v-col>
                <v-col cols="12" md="2" class="mt-2 text-right">
                    <v-chip class="my-2 px-2" small>
                        <b>{{ statusBillets[2] }}</b>
                    </v-chip>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12" md="10" class="mt-2 text-left">
                    <v-avatar class="mr-3" color="red lighten-5" rounded size="36"><v-icon color="red lighten-1"
                            size="24">event_busy</v-icon></v-avatar>
                    <span class="text-subtitle-2 red--text text--lighten-1">Boletos vencidos</span>
                </v-col>
                <v-col cols="12" md="2" class="mt-2 text-right">
                    <v-chip class="my-2 px-2" small>
                        <b>{{ statusBillets[3] }}</b>
                    </v-chip>
                </v-col>
            </v-row>
            <!-- :stepsOn="stepsOn" -->
            <!-- :verifySteps="verifySteps" -->
            <ManagerBillets :updateBilletLine="updateBilletLine" :getBillets="getBillets" :billets="billets"
                :profilesCbos="profilesCbos" :banksProfileRegisterCbo="banksProfileRegisterCbo"
                :toogleManagerBilletsDialog="toogleManagerBilletsDialog" :managerBilletsDialog="managerBilletsDialog" />
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-center py-3">
            <v-btn @click="toogleManagerBilletsDialog" class="" text color="success">Gerenciar meus boletos</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import ManagerBillets from "../../../../components/clients/billets/newManagerBillets/ManagerBilletsPanel.vue";
export default {
    methods: {
        startRequest() {
            this.$store.commit("showLoader", {
                circle: true,
            });
            //console.log("loaders");
            this.getConfiguredBanks(true).then((_) => {
                this.getProfiles(true).then((_) => {
                    let query = {
                        number_bank: "",
                        start_date: "",
                        end_date: "",
                        dataVencimento: "",
                        numeroInscricao: "",
                        email: "",
                        profile_id: "",
                        status: "",
                        batch: "",
                    };
                    this.getBillets(query, true, true).then((_) => {
                        // this.verifySteps();
                        this.$store.commit("hideLoader");
                    });
                });
            });
        },
        toogleManagerBilletsDialog() {
            this.managerBilletsDialog = !this.managerBilletsDialog;
        },
        async getConfiguredBanks(loader) {
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/user/bank/getUserBank",
                query: null,
                method: "get",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.banksProfileRegisterCbo = [
                    ...response.data.map((obj) => {
                        return {
                            number_bank: obj.bank.number_bank,
                            pg_bank_favoured_id: obj.id,
                            bank_name: obj.bank.bank_name,
                        };
                    }),
                ];
                this.banks = [...response.data];
            });
        },
        async getProfiles(loader) {
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/user/profile/get-all",
                query: null,
                method: "get",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.profilesCbos = [
                    ...response.data.map((prf) => {
                        return { id: prf.id, name: prf.profile_name };
                    }),
                ];
                this.profiles = [...response.data];
            });
        },
        async getBillets(parans, loader, mounted) {
            var obj = {
                noLoader: loader,
                url: "/api/v1/title/get-titles",
                query: { ...parans },
                method: "post",
            };
            //console.log("updateLastBilletsParans");
            this.$store.commit("updateLastBilletsParans", obj.query);
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.billets = [];
                let formatBillets = [];
                formatBillets = [
                    ...response.data.map((billet) => {
                        let payer = { ...billet.data_title.pagador };
                        let data = { ...billet.data_title };

                        // delete billet.data_title;
                        // delete billet.desconto;
                        // delete billet.desconto;

                        delete data.desconto;
                        delete data.jurosMora;
                        delete data.multa;
                        delete data.pagador;
                        let obj = { ...billet, ...data, ...payer };
                        // console.log("montado",obj)
                        return obj;
                    }),
                ];
                // console.log("array",formatBillets)
                this.billets = [...formatBillets];
                if (mounted) {
                    let arr = [0, 0, 0, 0]
                    response.data.map(blt => {
                        switch (blt.status) {
                            case "Liquidado":
                                arr[0]++
                                return;
                            case "Aberto":
                                arr[1]++
                                return;
                            case "Andamento":
                                arr[2]++
                                return;
                            case "Vencido":
                                arr[3]++
                                return;
                        }
                    })
                    this.statusBillets = [...arr]
                }
            });
        },
        updateBilletLine(id, status) {
            let index = this.billets.findIndex((billet) => billet.id == id);
            this.billets[index].status = status;
        },
    },
    data() {
        return {
            managerBilletsDialog: false,
            banksProfileRegisterCbo: [],
            profilesCbos: [],
            billets: [],
            statusBillets: [0, 0, 0, 0]
        }
    },
    mounted() {
        this.startRequest();
    },
    components: {
        ManagerBillets,
    },
}
</script>

<style lang="scss" scoped></style>