<template>
    <div>
        <v-dialog v-model="dataRecurrenceDialog" persistent max-width="450px">
            <v-card>
                <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2 mb-3"><v-app-bar height="50" flat
                        color="rgba(0, 0, 0, 0)">
                        <v-toolbar-title class="text-h5 pl-0 pt-0">
                            Recorrência
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                    </v-app-bar></v-card-title>
                <v-card-text class="pt-3">
                    <v-row class="px-5">
                        <v-col class="pl-4" cols="12">
                            <h4><small color="grey--text">Status: </small></h4>
                            <h3 class="mb-2 success--text"> {{ formatStatus(dataRecurrence.reasonCode)[0] }} <v-icon
                                    color="success">{{ formatStatus(dataRecurrence.reasonCode)[1] }}</v-icon>
                            </h3>
                            <h3 class="mb-2 mt-5"><v-icon color="">mdi mdi-calendar-sync</v-icon> Periodicidade:
                                <span class="primary--text">{{
                                    dataRecurrence.interval }}</span>
                            </h3>

                            <h3 class="ml-1 mb-2 mt-5">Vencimento dia: <span class="primary--text">{{
                                dataRecurrence.recurrence_day }}</span>
                            </h3>

                            <h3 class="ml-1 mb-2 mt-5">Próximo vencimento: <span class="primary--text">{{
                                dataRecurrence.nextRecurrency |
                                    formatDate }}</span>
                            </h3>

                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                    <v-spacer></v-spacer>
                    <v-btn color="red" text @click="toggleDataRecurrenceDialog"> Fechar </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // key: value
        }
    },
    methods: {
        formatStatus(status) {
            switch (status) {
                case 0:
                    return ["Ativa", "mdi mdi-check-circle", "success", "success--text"];
                case 1:
                    return ["Reativada", "mdi mdi-check-circle", "success", "success--text"];
                case 2:
                    return ["Finalizada", "mdi mdi-close-octagon-outline", "blue-grey lighten-3", "blue-grey--text text--lighten-3"];
                case 3:
                    return ["Desativada", "mdi mdi-check-circle", "success", "success--text"];
                // case 4:
                //     return ["Ativa", "mdi mdi-check-circle", "success", "success--text"];
                // case 5:
                //     return ["Ativa", "mdi mdi-check-circle", "success", "success--text"];
                default:
                    return ["Desativada", "block", "grey darken-1", "grey--text text--darken-1"];
            }
        },
    },
    props: {
        dataRecurrence: Object,
        dataRecurrenceDialog: Boolean,
        toggleDataRecurrenceDialog: Function
    }
}
</script>

<style lang="scss" scoped></style>