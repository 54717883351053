<template>
    <v-row justify="center">
        <v-dialog v-model="creditCardDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primaryNew">
                    <v-toolbar-title>CARTÕES DE CRÉDITO</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon dark @click="toogleCreditCardDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <div style="max-width: 1600px; margin: auto; position: relative;">
                        <v-row class="mt-10 mb-2 d-flex align-start justify-space-between">
                            <v-col cols="12">
                                <h4 class="titlePages text-left font-weight-medium mb-4">Configurações
                                </h4>
                                <p style="max-width: 60%;" class="text-body-2 font-weight-light text-left mt-2">
                                    Configure bancos para geração de boletos, crie perfis para diferentes padronizações,
                                    envie, gere
                                    PDFs e emita novos boletos para acelerar e facilitar suas cobranças.
                                </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-start">
                                <CardOperator :updateConfiguredOperators="updateConfiguredOperators" />
                                <CardTransections :creditCardDialog="creditCardDialog" :configuredOperators="configuredOperators" />
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- <v-divider></v-divider> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import CardOperator from './CardOperator.vue'
import CardTransections from './CardTransections.vue'
export default {
    methods: {
        updateConfiguredOperators(data) {
            this.configuredOperators = [...data]
        }
    },
    data() {
        return {
            configuredOperators: []
        }
    },
    props: {
        toogleCreditCardDialog: Function,
        creditCardDialog: Boolean
    },
    components: {
        CardOperator,
        CardTransections
    },
}
</script>

<style lang="scss" scoped></style>