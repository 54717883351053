<template>
    <v-row justify="center">
        <v-dialog v-model="billetDialog" fullscreen hide-overlay transition="dialog-bottom-transition">
            <v-card>
                <v-toolbar dark color="primaryNew">
                    <v-toolbar-title>GERENCIAMENTO DE BOLETOS</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-tooltip left>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn v-bind="attrs" v-on="on" icon dark @click="toogleBilletDialog">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                            <span>Fechar</span>
                        </v-tooltip>
                    </v-toolbar-items>
                </v-toolbar>
                <v-card-text>
                    <div style="max-width: 1600px; margin: auto; position: relative;">
                        <v-row class="mt-10 mb-2 d-flex align-start justify-space-between">
                            <v-col cols="12">
                                <h4 class="titlePages text-left font-weight-medium mb-4">Configurações
                                </h4>
                                <p style="max-width: 60%;" class="text-body-2 font-weight-light text-left mt-2">
                                    Configure bancos para geração de boletos, crie perfis para diferentes padronizações,
                                    envie, gere
                                    PDFs e emita novos boletos para acelerar e facilitar suas cobranças.
                                </p>
                            </v-col>
                            <v-col cols="12" class="d-flex justify-start">
                                <CardBank />
                                <CardProfile class="ml-10" />
                                <CardBillet />
                            </v-col>
                        </v-row>
                    </div>
                </v-card-text>
                <!-- <v-divider></v-divider> -->
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import CardBank from "./CardBank.vue"
import CardProfile from "./CardProfile.vue"
import CardBillet from "./CardBillet.vue"

export default {
    props: {
        toogleBilletDialog: Function,
        billetDialog: Boolean
    },
    components: {
        CardBank,
        CardProfile,
        CardBillet
    },
}
</script>

<style lang="scss" scoped></style>