var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5",attrs:{"width":"550"}},[_c('v-card-title',[_vm._v(" Operadoras ")]),_c('v-card-subtitle',[_vm._v(" Lista de operadoras de cartões cadastradas ")]),_c('v-divider',{staticClass:"deep-purple darken-1",staticStyle:{"border":"solid 2px"}}),_c('div',{staticStyle:{"position":"relative"}},[_c('v-fab-transition',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-3",attrs:{"color":"deep-purple darken-1","fab":"","dark":"","small":"","absolute":"","top":"","right":""},on:{"click":_vm.toogleRegisterOperatorDilog}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Cadastrar nova operadora")])])],1)],1),_c('v-card-text',{},[_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.operators,"items-per-page":5,"no-data-text":"Nenhuma operadora configurada"},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primaryNew","dark":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":"","rounded":""}},[_c('v-subheader',{staticClass:"text-subtitle-2"},[_c('b',{staticClass:"primaryNew--text"},[_vm._v(" "+_vm._s(item.name)+" ")])]),_c('v-divider',{staticClass:"mb-2"}),_c('v-list-item-group',{attrs:{"color":"primaryNew"}},[_c('v-list-item',{on:{"click":function($event){return _vm.editConfigOperator(item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("drive_file_rename_outline")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Editar operadora")])],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.confirmDelConfigOperator(item)}}},[_c('v-list-item-icon',{staticClass:"mr-4"},[_c('v-icon',[_vm._v("delete_forever")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Excluir operadora")])],1)],1)],1)],1)],1)]}}])}),_c('RegisterOperator',{attrs:{"adm":false,"configuredOperators":_vm.configuredOperators,"editConfigData":_vm.editConfigData,"registerOperatorDilog":_vm.registerOperatorDilog,"toogleRegisterOperatorDilog":_vm.toogleRegisterOperatorDilog,"getOperators":_vm.getOperators}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }