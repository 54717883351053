<template>
    <v-dialog persistent v-model="registerbankDilog" scrollable max-width="850px">
        <v-card>
            <v-card-title style="border-bottom: 1px solid #ccc;" class="px-2"><v-app-bar height="50" flat
                    color="rgba(0, 0, 0, 0)">
                    <v-toolbar-title class="text-h5 pl-0 pt-0">
                        {{ false ? "Editar banco" : "Configurar novo banco" }}
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                    <!-- <v-fab-transition> -->
                    <v-btn @click="toogleRegisterbankDilog" class="mt-6" color="red" fab icon dark x-small absolute top
                        right>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <!-- </v-fab-transition> -->
                </v-app-bar></v-card-title>
            <v-card-text v-if="!formBankFields.length" class="py-15">
                <v-row class="px-3 py-5">
                    <v-col class="pl-5" cols="1"></v-col>
                    <v-col class="pl-5" cols="10">
                        <v-combobox class="requiredField" :rules="$store.state.formRules.required" clearable
                            v-model="bank" :items="banks" label="Selecione um banco" item-text="bank_name"
                            item-value="number_bank" @change="getFields"></v-combobox>
                    </v-col>
                    <v-col class="pl-5" cols="1"></v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="formBankFields.length" class="pt-0">
                <div class="hideFormToLoad" v-if="loadValidation">
                    <v-container style="height: 500px;">
                        <v-row class="fill-height" align-content="center" justify="center">
                            <v-col class="text-subtitle-1 text-center" cols="12">
                                Carregando campos...
                            </v-col>
                            <v-col cols="6">
                                <v-progress-linear color="primaryNew" indeterminate rounded
                                    height="3"></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-container>
                </div>
                <v-tabs color="primaryNew" v-model="tabs">
                    <v-tabs-slider class="pa-5"></v-tabs-slider>
                    <v-tab v-for="(tabData, i) in formBankFields" :key="i" class="">
                        {{ tabData.tabName }}
                    </v-tab>
                </v-tabs>
                <v-form ref="bankForm">


                    <v-tabs-items light v-model="tabs" style="min-height: 350px; border-top: 1px solid #ccc">
                        <v-tab-item class="tabValidator" v-for="(tabData, i) in formBankFields" :key="i">
                            <v-row class="px-3 mt-4">
                                <v-col class="pl-5 py-0" cols="12">
                                    <div class="infoText pt-2">
                                        <h6 class="subtitle-1 grey--text text--darken-1 font-weight-medium">
                                            <v-icon color="primary" class="mr-2 mb-1">info</v-icon>
                                            {{ tabData.infoTitle }}
                                        </h6>
                                        <p class="caption grey--text text--darken-1 mb-5 mt-1 ml-1">
                                            {{ tabData.generalInfo }}
                                        </p>
                                    </div>
                                </v-col>
                                <v-col v-if="i == 0" class="pl-5" cols="6">
                                    <v-select class="requiredField" :rules="$store.state.formRules.required" clearable
                                        v-model="bank" :items="banks" label="Selecione um banco" item-text="bank_name"
                                        item-value="number_bank" @change="getFields"
                                        hint="Lista dos seus bancos conveniados" :persistent-hint="true"></v-select>
                                </v-col>
                                <v-col style="position: relative;" v-for="(field, i) in tabData.fields" :key="i"
                                    class="pl-5" cols="6">
                                    <!-- hint: field.aboutField,
                                    'persistent-hint': field.aboutField ? true : false -->
                                    <v-text-field-money v-if="field.type == 'money'"
                                        :class="field.validation ? 'requiredField' : ''" v-model="formData[field.name]"
                                        v-bind:label="field.label" v-bind:properties="{
                                            rules: validRegex(field.validRegex, field.validation, field.validMessage, field.type),
                                            prefix:
                                                formData[field.name] &&
                                                    formData[field.name].length
                                                    ? 'R$'
                                                    : '',
                                            disabled: field.desabled,
                                            readonly: false,
                                            outlined: false,
                                            clearable: true,
                                            placeholder: ' ',
                                        }" v-bind:options="{
                                            locale: 'pt-BR',
                                            length: 11,
                                            precision: 2,
                                            empty: null,
                                        }" />

                                    <v-select v-if="field.type == 'select'" :disabled="field.desabled"
                                        :class="field.validation ? 'requiredField' : ''" @change=""
                                        :rules="validRegex(field.validRegex, field.validation, field.validMessage, field.type)"
                                        :label="field.label" v-model="formData[field.name]" :items="field.items"
                                        item-text="label" item-value="val"></v-select>
                                    <!-- :hint="field.items.findIndex((item) => item.val == formData[field.name]) > -1 ? field.items[field.items.findIndex((item) => item.val == formData[field.name])].aboutField : field.aboutField"
                                        :persistent-hint="field.aboutField.length ? true : false" -->

                                    <v-text-field :disabled="field.desabled"
                                        :rules="validRegex(field.validRegex, field.validation, field.validMessage, field.type)"
                                        :class="field.validation ? 'requiredField' : ''" v-if="field.type == 'number'"
                                        type="number" min="0" clearable v-model="formData[field.name]"
                                        :label="field.label"></v-text-field>
                                    <!-- :hint="field.aboutField"
                                        :persistent-hint="field.aboutField ? true : false" -->
                                    <v-text-field :class="field.validation ? 'requiredField' : ''"
                                        :rules="validRegex(field.validRegex, field.validation, field.validMessage, field.type)"
                                        :disabled="field.desabled" v-if="field.type == 'text'" clearable
                                        v-model="formData[field.name]" :label="field.label"></v-text-field>
                                    <!-- :hint="field.aboutField" 
                                        :persistent-hint="field.aboutField ? true : false" -->
                                    <div v-if="field.aboutField && field.type != 'select'" :class="['specialHint']">
                                        <small>{{ field.aboutField
                                            }}</small>
                                    </div>

                                    <div v-if="field.aboutField && field.type == 'select'" :class="['specialHint']">
                                        <small>{{
                                            field.items.findIndex((item) => item.val == formData[field.name]) > -1 ?
                                                field.items[field.items.findIndex((item) => item.val ==
                                                    formData[field.name])].aboutField : field.aboutField }}</small>
                                    </div>
                                </v-col>

                            </v-row>
                        </v-tab-item>
                    </v-tabs-items>
                </v-form>
            </v-card-text>
            <v-card-actions class="px-0 mx-3" style="border-top: 1px solid rgb(220, 220, 220)">
                <div v-if="infoDoc ? true : false">

                    <v-icon color="primary" class="ml-1 mr-2">help</v-icon> <span
                        style="font-size: 14px; margin-top: 1px;">Em caso de dúvidas leia a documentção <a
                            @click="toogleinfoDocDialog" class="ml-1" style="text-decoration: underline;">clicando
                            aqui</a></span>
                </div>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="toogleRegisterbankDilog">
                    Fechar
                </v-btn>
                <v-btn v-if="formBankFields.length" color="green" text @click="saveConfigBank"> Salvar </v-btn>
            </v-card-actions>
        </v-card>
        <InformativeText :infoDoc="infoDoc" :toogleinfoDocDialog="toogleinfoDocDialog" :infoDocDialog="infoDocDialog" />
    </v-dialog>
</template>

<script>
import InformativeText from './InformativeText.vue'
export default {
    watch: {
        registerbankDilog(v) {
            if (v) {
                if (this.editConfigData) {
                    this.bank = this.editConfigData.bank.number_bank
                    this.getFields().then(res => {
                        this.formData = { ...this.editConfigData }
                        this.bank = this.editConfigData.bank.number_bank
                        this.tabs = 0
                        this.$refs.bankForm.resetValidation();
                    })
                }
            } else {
                this.$refs.bankForm ? this.$refs.bankForm.resetValidation() : '';
                this.loadValidation = true
                this.formData = {}
                this.formBankFields = []
                this.bank = ""
                this.tabs = 0
            }
        }
    },
    methods: {
        toogleinfoDocDialog() {
            this.infoDocDialog = !this.infoDocDialog
        },
        getCboBanks() {
            var obj = {
                noLoader: true,
                url: "/api/title/bank/get-all",
                query: null,
                method: "get",
            };

            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                //console.log("bancos", response.data);
                this.banks = [...response.data];
            });
        },
        validRegex(regX, validation, msg, type) {
            let valid = []
            // console.log('valid')
            if (regX) {
                return [v => (v && v.match(/regX.toString()/) == null) || msg, this.$store.state.formRules.required]
            }
            if (validation) {
                if (type == "money") {
                    // console.log('valid money')
                    return [v => this.aceptZero(v) || '']
                }
                return [v => !!v || '']
            }
            return valid
        },
        aceptZero(v) {
            //console.log('zero')
            if (!v) {
                v = "0,00"
            }
            let val = v.replace(',', '.')
            if (parseFloat(val).toFixed(2) == 0) { return false }
            if (val) { return true } else { return false }
        },
        async getFields() {
            let numb
            if (typeof this.bank === 'object') {
                numb = this.bank.number_bank
            } else {
                numb = this.bank
            }
            let obj = {
                url: "/api/v1/title/user/bank/getValidations",
                query: {
                    number_bank: numb
                },
                method: "post",
            };
            await this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.formBankFields = [...response.data]
                this.infoDoc = response.data[0].aboutProcess

                setTimeout(() => {
                    var collection = document.getElementsByClassName('v-tab');
                    for (let i = 0; i < collection.length; i++) {
                        setTimeout(() => {
                            var lght = (collection.length - 1)
                            collection[lght - i].click()
                            console.log('count')
                        }, 10);

                    }
                    setTimeout(() => {
                        this.loadValidation = false
                    }, 300);
                }, 1000);
            });
        },
        validTabs() {
            let tabsFrm = document.getElementsByClassName('tabValidator')
            let tabs = document.getElementsByClassName('v-tab')
            for (let i = 0; i < tabsFrm.length; i++) {
                // tabs[i].getElementsByClassName('error--text')
                if (tabsFrm[i].getElementsByClassName('error--text').length) {
                    tabs[i].classList.add("tabHasError");
                    // console.log(tabsFrm[i].getElementsByClassName('error--text').length);
                } else {
                    tabs[i].classList.remove("tabHasError");
                }

            }
        },
        saveConfigBank() {
            // console.log('bbbb');
            // return;
            if (!this.$refs.bankForm.validate()) {
                setTimeout(() => {
                    this.validTabs()
                }, 10);
                return;
            }
            this.formData.number_bank = this.bank;
            //this.formData.service_id = 3;
            this.formData.partner_id = localStorage.getItem("partner_id");
            if (!this.formData.id) {
                this.formData.number_bank = this.formData.number_bank.number_bank
            }
            let query = {
                ...this.formData,
            };
            delete query.bank;
            var obj = {
                url: `${this.formData.id
                    ? "/api/v1/title/user/bank/updateTitleConfig"
                    : "/api/v1/title/user/bank/createUserBank"
                    }`,
                query: query,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                if (!response) {
                    return;
                }
                if (this.adm) {
                    console.log('not - async')
                    this.getConfiguredBanks()
                    this.toogleRegisterbankDilog()
                } else {
                    console.log('async')
                    this.getConfiguredBanks().then((_) => {
                        this.toogleRegisterbankDilog();
                        // if (this.verifySteps) {
                        //   this.verifySteps()
                        // }
                    });
                }
                let opts = {
                    message: `Configuração ${this.formData.id ? "atualizada" : "criada"
                        } com sucesso`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
            });
        },
    },
    data() {
        return {
            loadValidation: true,
            infoDoc: "",
            infoDocDialog: false,
            bank: "",
            banks: [],
            formData: {},
            tabs: null,
            formBankFields: []
        }
    },
    mounted() {
        this.getCboBanks();
        // this.getFields();
    },
    props: {
        adm: Boolean,
        verifySteps: Function,
        registerbankDilog: Boolean,
        toogleRegisterbankDilog: Function,
        editConfigData: Object,
        getConfiguredBanks: Function,
    },
    components: {
        InformativeText,
    },
}
</script>

<style>
.specialHint {
    /* position: absolute; */
    line-height: 12px;
    margin-top: -15px;
}

.error--text .specialHint {
    margin-top: 0 !important;
}

.hideFormToLoad {
    left: 0;
    z-index: 999;
    background-color: white;
    position: absolute;
    width: 100%;
    height: calc(100% - 77px);
}

.v-tab.tabHasError {
    color: red !important;
}

.v-tab.tabHasError::after {
    content: "*";
}
</style>