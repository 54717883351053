<template>
    <v-card class="ml-8 mt-5" width="1018">
        <v-row justify="center">
            <v-col cols="12" md="8" class="">
                <v-card-title>
                    Minhas transações
                </v-card-title>
                <v-card-subtitle>
                    Selecione ao lado uma operadora para visualizar as transações
                </v-card-subtitle>
            </v-col>
            <v-col cols="12" md="4" class="px-8 pt-9 pb-0 d-flex">
                <!-- <span class="pt-3 pr-3 font-weight-medium">Selecione:</span> -->
                <v-select v-model="operator" @change="setOperator" item-text="name" item-value="name" class="" dense
                    :items="configuredOperators" label="Operadora" outlined>
                </v-select>
            </v-col>
        </v-row>
        <v-divider style="border: solid 2px;" class="blue-grey darken-1"></v-divider>
        <v-card-text class="">
            <TransectionList :operator="operator" />
        </v-card-text>
    </v-card>
</template>

<script>
import TransectionList from './TransectionList.vue'
export default {
    watch: {
        configuredOperators(v) {
            if (v.length) {
                this.operator = this.configuredOperators[0].name
            }
        },
        creditCardDialog(v) {
            console.log('setoperadora')
            if (v) {
                this.operator = this.configuredOperators[0].name
            }
        }
    },
    methods: {
        setOperator() {

        }
    },
    data() {
        return {
            operator: "",
            operatorSelected: ""
        }
    },
    props: {
        configuredOperators: Array,
        creditCardDialog: Boolean
    },
    components: {
        TransectionList,
    },
}
</script>

<style lang="scss" scoped></style>