import { render, staticRenderFns } from "./CardOperator.vue?vue&type=template&id=1da62470&scoped=true&"
import script from "./CardOperator.vue?vue&type=script&lang=js&"
export * from "./CardOperator.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1da62470",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFabTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VDataTable,VDivider,VFabTransition,VIcon,VList,VListItem,VListItemContent,VListItemGroup,VListItemIcon,VListItemTitle,VMenu,VSubheader,VTooltip})
