<template>
    <v-card class="mt-5" width="550">
        <v-card-title>
            Operadoras
        </v-card-title>
        <v-card-subtitle>
            Lista de operadoras de cartões cadastradas
        </v-card-subtitle>
        <v-divider style="border: solid 2px;" class="deep-purple darken-1"></v-divider>
        <div style="position: relative;">
            <v-fab-transition>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="mr-3" @click="toogleRegisterOperatorDilog" v-bind="attrs" v-on="on"
                            color="deep-purple darken-1" fab dark small absolute top right>
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </template>
                    <span>Cadastrar nova operadora</span>
                </v-tooltip>
            </v-fab-transition>
        </div>
        <v-card-text class="">
            <v-data-table :headers="headers" :items="operators" :items-per-page="5" class="elevation-0"
                no-data-text="Nenhuma operadora configurada">
                <!-- <template v-slot:item.created_at="{ item }">
                    {{ buildDate(item.created_at) }}
                </template>
                <template v-slot:item.credentials="{ item }">
                    <div class="py-2"><small><b>merchant id:</b></small> {{ item.credentials.merchant_id }}</div>
                    <div class="pb-2"><small><b>merchant key:</b></small> {{ item.credentials.merchant_key }}</div>
                </template> -->
                <template v-slot:item.actions="{ item }">
                    <v-menu bottom left>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn color="primaryNew" dark icon v-bind="attrs" v-on="on">
                                <v-icon>more_vert</v-icon>
                            </v-btn>
                        </template>
                        <v-list dense rounded class="">
                            <v-subheader class="text-subtitle-2">
                                <b class="primaryNew--text">
                                    {{ item.name }}
                                </b></v-subheader>
                            <v-divider class="mb-2"></v-divider>
                            <v-list-item-group color="primaryNew" class="">
                                <v-list-item @click="editConfigOperator(item)">
                                    <v-list-item-icon class="mr-4">
                                        <v-icon>drive_file_rename_outline</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Editar operadora</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                                <v-list-item @click="confirmDelConfigOperator(item)">
                                    <v-list-item-icon class="mr-4">
                                        <v-icon>delete_forever</v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                        <v-list-item-title>Excluir operadora</v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list>
                    </v-menu>
                </template>
            </v-data-table>
            <RegisterOperator :adm="false" :configuredOperators="configuredOperators" :editConfigData="editConfigData"
                :registerOperatorDilog="registerOperatorDilog"
                :toogleRegisterOperatorDilog="toogleRegisterOperatorDilog" :getOperators="getOperators" />
        </v-card-text>
    </v-card>
</template>

<script>
import RegisterOperator from '../../../../../components/clients/billets/CreditCards/RegisterOperator.vue';
export default {
    methods: {
        getOperators() {
            var obj = {
                url: "/api/v1/paymentCredentials/getAll",
                query: null,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.operators = [...response.data];
            });
        },
        getConfiguredOperators() {
            // function que carrega o select do form
            var obj = {
                url: "/api/v1/paymentGateways/getAll",
                query: null,
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                this.configuredOperators = [...response.data];
                this.updateConfiguredOperators(response.data)
            });
        },
        buildDate(date) {
            let day = new Date(date).getDate()
            let month = date.split('-')[1]
            let yaer = date.split('-')[0]
            return day + "/" + month + "/" + yaer
        },
        editConfigOperator(data) {
            let obj = {

            };
            this.editConfigData = { ...data };
            console.log('edit', this.editConfigData)
            this.toogleRegisterOperatorDilog();
        },
        confirmDelConfigOperator(data) {
            let obj = {
                message: `Você deseja excluir a configuração da operadora <b>${data.name}</b>?`,
                title: "Excluir",
                callback: (_) => {
                    this.delConfigOperator(data);
                },
                type: "warning",
                btConfirm: { name: "Sim", show: true },
                btCancel: { name: "Não", show: true },
            };
            this.$store.commit("confirmMessage/showConfirmMessage", obj);
        },
        delConfigOperator(data) {
            // "partner_id":68,
            //   "contract_id":129,
            //   "service_id" : 3,
            //   "id":5
            let obj = {
                url: "/api/v1/paymentCredentials/delete",
                query: {
                    id: data.id,
                    name: data.name
                    // service_id: 3,
                    // partner_id: localStorage.getItem("partner_id"),
                },
                method: "post",
            };
            this.$store.dispatch("request/doRequest", obj).then((response) => {
                // let index = this.payment.findIndex((payment) => payment.id === data.id);
                // this.payment.splice(index, 1);
                let opts = {
                    message: `Configuração removida com sucesso.`,
                    type: "success",
                };
                this.$store.commit("showAlert", opts);
                this.getOperators();
            });
        },
        toogleRegisterOperatorDilog() {
            this.registerOperatorDilog = !this.registerOperatorDilog;
            if (!this.registerOperatorDilog) {
                this.editConfigData = null;
            }
        },
    },
    data() {
        return {
            configuredOperators: [],
            operators: [],
            editConfigData: null,
            registerOperatorDilog: false,
            headers: [
                {
                    text: "Nome",
                    value: "name",
                    align: "left",
                    sortable: false,
                },
                // {
                //     text: "Data de início",
                //     value: "created_at",
                //     align: "center",
                //     sortable: false,
                // },
                // {
                //     text: "Credenciais",
                //     value: "credentials",
                //     align: "left",
                //     sortable: false,
                // },
                {
                    text: "Url de callback",
                    value: "callback_url",
                    align: "left",
                    sortable: false,
                },
                {
                    text: "Ações",
                    value: "actions",
                    align: "center",
                    sortable: false,
                },
            ],
        }
    },
    mounted() {
        this.getOperators();
        this.getConfiguredOperators();
    },
    props: {
        updateConfiguredOperators: Function
    },
    components: {
        RegisterOperator
    },
}
</script>

<style lang="scss" scoped></style>